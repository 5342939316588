// Loosely based on https://github.com/Exelord/ember-rollbar-client/

import { getOwner } from '@ember/application';
import Service, { inject as service } from '@ember/service';
import Rollbar from 'rollbar';

export default class ErrorReporterService extends Service {
  @service session;

  get userConsent() {
    const consents = this.session?.user?.consents;
    return consents ? consents.includes('error_tracking') : true;
  }

  get enabled() {
    return this.config.enabled;
  }

  get person() {
    return this.notifier.options.payload.person;
  }
  set person(value) {
    if (this.userConsent) {
      this.notifier.configure({ payload: { person: value } });
    }
  }

  get notifier() {
    return new Rollbar(this.config);
  }

  get config() {
    return getOwner(this).resolveRegistration('config:environment')
      .rollbarConfig;
  }

  // Notifications
  critical(...args) {
    if (!this.userConsent) return;
    return this.notifier.critical(...args);
  }
  error(...args) {
    if (!this.userConsent) return;
    return this.notifier.error(...args);
  }
  warning(...args) {
    if (!this.userConsent) return;
    return this.notifier.warning(...args);
  }
  info(...args) {
    if (!this.userConsent) return;
    return this.notifier.info(...args);
  }
  debug(...args) {
    if (!this.userConsent) return;
    return this.notifier.debug(...args);
  }
}

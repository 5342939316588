import MixPanel from 'ember-metrics/metrics-adapters/mixpanel';
import { inject as service } from '@ember/service';

export default class MixpanelAdapter extends MixPanel {
  @service mixpanel;

  identify() {
    if (!this.mixpanel.userConsent) return;
    super.identify(...arguments);
  }

  trackPage() {
    if (!this.mixpanel.userConsent) return;
    super.trackPage(...arguments);
  }

  trackEvent() {
    if (!this.mixpanel.userConsent) return;
    super.trackEvent(...arguments);
  }
}

const DOCS = {
  keywordViewsAndLists:
    'https://docs.nightwatch.io/en/articles/4878015-creating-views-and-keyword-lists',
  websiteIsntRanking:
    'https://docs.nightwatch.io/en/articles/4874366-faq-the-website-isn-t-ranking',
  tos: 'https://docs.nightwatch.io/en/articles/4895540-terms-of-service',
  privacy: 'https://docs.nightwatch.io/en/articles/4895535-privacy-policy',
  trackingKeywordRankings:
    'https://docs.nightwatch.io/en/articles/4874339-tracking-keywords',
  faqMetrics:
    'https://docs.nightwatch.io/en/articles/5361364-how-do-you-calculate-average-position-search-volume-click-potential-and-search-visibility-index',
  whatNightwatchTracks:
    'https://docs.nightwatch.io/en/articles/4877997-types-of-rankings',
  backlinksTracking:
    'https://docs.nightwatch.io/en/articles/4894848-monitoring-backlinks',
  gaPropertiesListingPermissionsError:
    'https://docs.nightwatch.io/en/articles/6704213-google-analytics-search-console-permissions-error',
};

export default DOCS;

import { AbortError } from '@ember-data/adapter/error';
import { isFetchError, isTimeoutError } from 'ember-ajax-fetch/errors';
import Ember from 'ember';
import { on } from 'rsvp';

export function initialize(appInstance) {
  const errorReporterService = appInstance.lookup('service:error-reporter');
  const oldOnError = Ember.onerror || function () {};

  // Only report the first instance of this error. Otherwise it will spam occurrences
  let removeChildReported = false;
  const ignoreRemoveChild = (error) => {
    if (removeChildReported) return true;
    if (
      error?.message ===
      "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
    ) {
      removeChildReported = true;
      return false;
    }
  };

  const ignoreRequest = (error) => {
    return (
      error?.isAdapterError || // Ignore Ember Data adapter errors
      isFetchError(error) || // Ignore failed request errors
      isTimeoutError(error) || // Ignore request timeouts
      error?.message === 'Network request failed' ||
      error?.message === 'Failed to fetch' ||
      error?.message === 'Ajax authorization failed'
    );
  };

  const ignoreError = (error) => {
    return (
      error instanceof AbortError ||
      error?.name === 'TransitionAborted' || // See https://github.com/emberjs/ember.js/issues/12505
      ignoreRequest(error) ||
      ignoreRemoveChild(error)
    );
  };

  const reportError = (...args) => {
    const [error] = args;
    if (ignoreError(error)) return;
    if (errorReporterService.enabled) errorReporterService.error(...args);
    throw error;
  };

  Ember.onerror = (...args) => {
    oldOnError(...args);
    reportError(...args);
  };

  // Fired when ember's internal promise implementation throws an unhandled exception
  on('error', (reason) => reportError(reason));
}

export default { initialize };

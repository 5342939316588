import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';
import ENV from 'nightwatch-web/config/environment';
import DOCS from '../../constants/docs';

export default class NwOnboardingFlowAnalyticsPropertyChooserComponent extends Component {
  @service googleAnalyticsIntegration;
  @service errorReporter;
  @service session;
  @service siteData;
  @service notifications;
  @service router;
  @tracked didFetchProfiles = false;
  @tracked isLoading = false;
  @tracked gaClientError = null;
  profiles = null;
  properties = null;
  scSites = null;

  get analyticsEnabled() {
    return this.session.user.googleAnalyticsEnabled;
  }

  get noProfiles() {
    return this.didFetchProfiles && this.session.user.profiles.length === 0;
  }

  get profilesForDropdown() {
    const profiles = this.profiles || [];
    const properties = this.properties || [];
    return [{ id: null, text: 'None' }].concat(
      profiles.map((profile) => ({
        id: profile.profile_id,
        text: `${profile.property} (${profile.profile_name}) - ${profile.property_id}`,
      })),
      properties.map((property) => ({
        id: property.property_id,
        text: `${property.property_name} (${property.property_id})`,
        ga4: true,
      }))
    );
  }

  get currentGAProfile() {
    const currentId =
      this.args.url.google_analytics_profile_id ||
      this.args.url.google_analytics_property_id;

    // Select first element 'None' by default.
    if (currentId == null) {
      return this.profilesForDropdown?.[0];
    }

    return this.profilesForDropdown?.find((e) => e.id === currentId);
  }

  get scSitesForDropdown() {
    const scSites = this.scSites || [];
    if (!scSites.length) return [];
    return [{ id: null, text: 'None' }].concat(
      scSites.map((site) => ({
        id: site,
        text: site,
      }))
    );
  }

  @action
  onInsert() {
    if (!ENV.googleAnalytics.enabled) return;
    if (this.analyticsEnabled) {
      this.populateProfiles.perform();
    } else {
      this.googleAnalyticsIntegration.prepareGoogleClient();
    }
  }

  @action
  onSelect(item) {
    if (item.ga4) {
      this.args.url.google_analytics_property_id = item.id;
      this.args.url.google_analytics_profile_id = null;
    } else {
      this.args.url.google_analytics_profile_id = item.id;
      this.args.url.google_analytics_property_id = null;
    }
  }

  selectFirstMatch() {
    if (this.args.url?.google_analytics_profile_id) return;
    if (!this.args?.autoSelect) return;
    const domain = this.args.url?.friendlyUrl;
    const matchedProfile = this.profiles.find((p) =>
      p.url.indexOf(domain > -1)
    );
    if (matchedProfile) {
      this.args.url.google_analytics_profile_id = matchedProfile.profile_id;
    }
    const matchedScSite = this.scSites.find((s) => s.indexOf(domain) > -1);
    if (matchedScSite) {
      this.args.url.search_console_url = matchedScSite;
    }
  }

  @task
  *populateProfiles() {
    if (!this.session.user.googleAnalyticsEnabled) return;
    this.isLoading = true;
    this.gaClientError = null;
    try {
      const data = yield this.googleAnalyticsIntegration.fetchProfiles();
      if (data.error) {
        if (data.error.match('PERMISSION_DENIED')) {
          const helpDocLink = DOCS.gaPropertiesListingPermissionsError;
          this.gaClientError = htmlSafe(
            `There was a permission error getting your analytics profiles,<br> please refer to this <a href="${helpDocLink}" target="_blank" style="color: #0078f0; text-decoration: underline;">help document</a> to fix this error.`
          );
          this.notifications.error(this.gaClientError, {
            autoClear: false,
            onClick: () => {
              window.open(helpDocLink, '_blank');
            },
          });
          return;
        } else {
          this.gaClientError = `There was a problem getting your profiles: ${data.error}, please contact support`;
          this.notifications.error(this.gaClientError, { autoClear: false });
          return;
        }
      }
      this.profiles = data.profiles;
      this.properties = data.properties;
      this.scSites = data.sc_sites;
      this.didFetchProfiles = true;
      this.selectFirstMatch();
    } catch (response) {
      this.gaClientError =
        response?.errors?.[0]?.detail?.error ||
        'Error while fetching profiles.';
      this.googleAnalyticsIntegration.prepareGoogleClient();
    }
    this.isLoading = false;
  }

  @action
  async connectGa() {
    if (this.siteData.isWhiteLabel) {
      const msg = htmlSafe(
        `To connect Google Analytics, please use the <b>non-whitelabeled version</b> of the app. After connecting your GA account, you can switch back to white-labeled version.`
      );
      return this.notifications.error(msg, {
        onClick: () => {
          this.router.transitionTo('dashboard.overview');
        },
      });
    }

    try {
      await this.googleAnalyticsIntegration.connectGa();
      this.isLoading = true;
      // this.session?.user.reload().then(() => {
      //   this.populateProfiles.perform();
      // });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.notifications.error(
        'There was an error while connecting your GA account. Please try again or contact support',
        { autoClear: true }
      );
      this.errorReporter.error('Connecting GA account failed.', error);
    }
  }
}

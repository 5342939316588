import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

/**
  @class ResetPasswordFormComponent

  Reset password form for requesting a password reset email
 */
export default class ResetPasswordFormComponent extends Component {
  @service router;
  @service fetch;
  @service notifications;

  @tracked email;
  @tracked error;

  @task
  *save() {
    try {
      yield this.fetch.post('/password_resets', {
        data: { email: this.email.toLowerCase() },
      });
    } catch (response) {
      this.error =
        response?.payload?.error ||
        'Something broke. Please contact us if error persists.';
      return;
    }
    this.notifications.success(
      'E-mail with password reset instructions sent. Check your inbox and visit the password reset link.'
    );
    this.router.transitionTo('login');
  }
}

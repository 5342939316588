import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';

export default class DiscoveryService extends Service {
  @service fetch;
  @tracked keywords = [];
  @tracked competitors = [];

  fetchKeywordSuggestions(url, countryCode) {
    return this.fetch.request(`/urls/${url.id}/keyword_suggestions`, {
      data: { country_code: countryCode },
    });
  }

  fetchCompetitorSuggestions(url) {
    return this.fetch.request(`/urls/${url.id}/competitor_suggestions`);
  }

  fetchKeywordSuggestionsCount(url) {
    return this.fetch.request(`/urls/${url.id}/keyword_suggestion_count`);
  }

  clearEmptyKeywords() {
    this.keywords = this.keywords.filter((keyword) => keyword.query);
  }
}

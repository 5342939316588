import Service, { inject as service } from '@ember/service';
import underscorifyKeys from '../utils/underscorify-keys';

export default class MixpanelService extends Service {
  @service fetch;
  @service session;
  @service metrics;
  @service theme;

  get userConsent() {
    const consents = this.session.user?.consents;
    return consents ? consents.includes('mixpanel_tracking') : true;
  }

  afterLogin(userMetrics) {
    if (!this.userConsent) {
      this.metrics.invoke('uninstall', 'Mixpanel', {});
      return;
    }

    this.metrics.identify('Mixpanel', {
      ...underscorifyKeys(userMetrics),
      distinctId: userMetrics.registrationEmail,
      $email: userMetrics.email,
      $name: userMetrics.name || userMetrics.email,
      $timezone: userMetrics.timezone,
      $app_version: 'nightwatch',
      $created: userMetrics.createdAtFormatted,
      theme: this.theme.current,
    });

    window.mixpanel?.people.increment('num_logins');
    window.mixpanel?.register({ app_version: 'nightwatch' });

    this.updateProps();
  }

  updateProps() {
    if (!this.userConsent) return;
    this.fetch.post('/users/update_mixpanel_props');
  }
}

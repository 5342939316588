import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import Route from '@ember/routing/route';

export default class PlanRoute extends Route {
  @service session;
  @service locator;
  @service externalScriptLoader;
  @service errorReporter;
  @service metrics;
  @service fetch;
  @service store;

  async beforeModel() {
    this.session.requireAuthentication();
    try {
      await this.externalScriptLoader.loadBillingScripts();
    } catch (error) {
      this.notifications.error(
        'There was an error while setting up the payment form. Please reload the page, or contact support if issue persists.'
      );
      this.errorReporter.error('Loading billing scripts failed.', error);
    }
  }

  model(params) {
    return hash({
      plan: this.store.findRecord('plan', params.plan_id),
      paymentData: this.fetch.request('/user/payment_token', {
        data: { version: '3' },
      }),
    });
  }

  afterModel() {
    if (this.session.user.invoice_country) {
      return;
    }
    // Don't return a promise – we don't want to wait for the locator to load
    this.locator
      .getCountry()
      .then((data) => {
        this.session.user.invoice_country = data['country_name'];
      })
      .catch((err) => {
        throw new Error('Country lookup failed. \n', err);
      });
  }

  @action
  willTransition() {
    this.session.user?.rollbackAttributes();
    return true;
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Subscribe Form' });
    return true;
  }
}

import RSVP from 'rsvp';
import Service, { inject as service } from '@ember/service';
import deepMerge from '../utils/deep-merge';
import underscorifyKeys from '../utils/underscorify-keys';

export default class UserSettingsService extends Service {
  @service store;
  @service siteData;
  @service session;
  @service errorReporter;

  init() {
    super.init(...arguments);
    this.set('userSettings', []);
  }

  getSetting({
    searchKeywordUrlId,
    dynamicViewId,
    backlinkViewId,
    siteAuditViewId,
  }) {
    if (dynamicViewId || backlinkViewId || siteAuditViewId) {
      searchKeywordUrlId = null;
    }

    // Because Ember data transforms integer ids into strings, we stringify values for comparison
    const toStringIfPresent = (val) => (val != null ? String(val) : null);
    return this.userSettings.find((setting) => {
      return (
        toStringIfPresent(setting.get('searchKeywordUrlId')) ==
          toStringIfPresent(searchKeywordUrlId) &&
        toStringIfPresent(setting.get('dynamicViewId')) ==
          toStringIfPresent(dynamicViewId) &&
        toStringIfPresent(setting.get('backlinkViewId')) ==
          toStringIfPresent(backlinkViewId) &&
        toStringIfPresent(setting.get('siteAuditViewId')) ==
          toStringIfPresent(siteAuditViewId)
      );
    });
  }

  saveSetting({
    searchKeywordUrlId,
    dynamicViewId,
    backlinkViewId,
    siteAuditViewId,
    settingsData,
  }) {
    if (dynamicViewId || backlinkViewId || siteAuditViewId) {
      searchKeywordUrlId = null;
    }

    // Don't persist when we're impersonating
    if (this.session.isAdminViewingUser) return;

    let setting = this.getSetting({
      searchKeywordUrlId,
      dynamicViewId,
      backlinkViewId,
      siteAuditViewId,
    });
    if (!setting) {
      setting = this.store.createRecord('user-setting', {
        searchKeywordUrlId: searchKeywordUrlId,
        dynamicViewId: dynamicViewId,
        backlinkViewId: backlinkViewId,
        siteAuditViewId: siteAuditViewId,
      });
      this.userSettings.pushObject(setting);
    }
    const newSettingsData = deepMerge(
      setting.get('settingsData'),
      settingsData
    );

    setting.set('settingsData', newSettingsData);
    return setting.save();
  }

  pushToSettings(userSettings) {
    const findExistingSetting = (setting) =>
      this.userSettings.find((existingSetting) => {
        return (
          existingSetting.get('searchKeywordUrlId') ==
            setting.get('searchKeywordUrlId') &&
          existingSetting.get('dynamicViewId') ==
            setting.get('dynamicViewId') &&
          existingSetting.get('backlinkViewId') ==
            setting.get('backlinkViewId') &&
          existingSetting.get('siteAuditViewId') ==
            setting.get('siteAuditViewId')
        );
      });
    userSettings.forEach((setting) => {
      const existingSetting = findExistingSetting(setting);
      if (existingSetting) {
        existingSetting.set('settingsData', setting.get('settingsData'));
      } else {
        this.userSettings.pushObject(setting);
      }
    });
  }

  loadData(params) {
    // Since this is called from model hooks, make sure it always resolves,
    // otherwise the route transition might be rejected in case of
    // user setting loading failure.
    return new RSVP.Promise((resolve) => {
      const setting = this.getSetting(params);
      if (setting) {
        // If we already have it, resolve immediately
        resolve(null);
      } else {
        this.fetchData(params)
          .then((userSettings) => {
            this.pushToSettings(userSettings);
          })
          .catch((error) => {
            this.errorReporter.error(
              'Loading user settings data failed.',
              error
            );
          })
          .finally(() => {
            resolve();
          });
      }
    });
  }

  fetchData(params) {
    return this.store.query('user-setting', underscorifyKeys(params));
  }
}

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isPast } from 'date-fns';
import { hash } from 'rsvp';

export default class OnboardingRoute extends Route {
  @service router;
  @service store;
  @service session;
  @service onboardingLocalStorage;

  beforeModel(transition) {
    if (this.session.user && !this.session.user.confirmed) {
      this.router.transitionTo('confirm-account');
    }
    this.session.requireAuthentication();
    this.session.requireActive();
    if (isPast(this.session.user?.active_until))
      this.router.transitionTo('plans');
    if (transition.targetName === 'onboarding.index')
      this.router.transitionTo('onboarding');
  }

  deactivate() {
    this.controller.url = null;
    this.controller.urlId = null;
    this.controller.currentStep = 'url';
  }

  model(params) {
    return hash({
      url: params.urlId
        ? this.store.findRecord('url', params.urlId)
        : this.store.createRecord('url', {
            matchNestedUrls: true,
            matchSubdomains: false,
          }),
      urlGroups: this.store.query('url-group', { static: true }),
    });
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model);
    controller.enteredRoute(
      model.url,
      model.urlGroups,
      this.onboardingLocalStorage.keywordPreciseLocation,
      this.onboardingLocalStorage.urlDevice,
      this.onboardingLocalStorage.urlEngine,
      this.onboardingLocalStorage.urlLanguageCode,
      this.onboardingLocalStorage.urlCountryCode,
      transition.to.queryParams.groupId
    );
  }
}

import AddonMetricsService from 'ember-metrics/services/metrics';
import { inject as service } from '@ember/service';

export default class MetricsService extends AddonMetricsService {
  @service errorReporter;

  identify() {
    try {
      super.identify(...arguments);
    } catch (error) {
      this.errorReporter.warning(error);
    }
  }

  trackPage() {
    try {
      super.trackPage(...arguments);
    } catch (error) {
      this.errorReporter.warning(error);
    }
  }

  trackEvent() {
    try {
      super.trackEvent(...arguments);
    } catch (error) {
      this.errorReporter.warning(error);
    }
  }
}

import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

/**
  @class ChangePasswordFormComponent

  For setting a new password

  @argument {String} resetToken - Required to change password
 */
export default class ChangePasswordFormComponent extends Component {
  @service router;
  @service fetch;
  @service notifications;

  @tracked password;
  @tracked passwordConfirmation;

  @tracked error;

  @task
  *save() {
    try {
      yield this.fetch.post('/password_resets/reset_password', {
        data: {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          reset_token: this.args.resetToken,
        },
      });
    } catch (response) {
      this.error =
        response?.payload?.error ||
        'Something broke. Please contact us if error persists.';
      return;
    }
    this.notifications.success(
      'Password reset successful. You can now login with your new password.'
    );
    this.router.transitionTo('login');
  }
}

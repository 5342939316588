import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import posthog from 'posthog-js';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import trackUnbounceConversion from 'nightwatch-web/utils/track-unbounce-conversion';
import config from '../config/environment';
import isTesting from 'nightwatch-web/utils/is-testing';

export default class SignupService extends Service {
  @service router;
  @service notifications;
  @service session;
  @service siteData;
  @tracked userEmail = null;

  get userEmail() {
    return this.userEmail;
  }

  @task({ drop: true })
  *submitEmail(email, planId) {
    try {
      let url = `${config.apiBaseURL}user/create_v2?email=${email}`;
      if (planId) {
        url += `&plan_id=${planId}`;
      }

      const response = yield fetch(url, {
        method: 'POST',
      });
      if (response.ok) {
        // Emit when email is submitted. Click on 'Next'.
        posthog.capture('email_submitted', { success: true });

        this.userEmail = email;
        this.notifications.success(
          'The email has been sent. Please check your inbox.',
          {
            autoClear: true,
          }
        );

        this.router.transitionTo('confirm-email', {
          queryParams: { email: email },
        });
      } else {
        // Emit when email is submitted. Click on 'Next'.
        posthog.capture('email_submitted', { success: false });
        // Handle non-200 responses
        this.notifications.error(
          (yield response.json()).error ??
            'Error sending email. Please try again.',
          {
            autoClear: true,
          }
        );
      }
    } catch (e) {
      console.log(e);
      this.notifications.error(
        (yield response.json()).error ??
          'Error sending email. Please try again.',
        {
          autoClear: true,
        }
      );
    }
  }

  @task({ drop: true })
  *submitVerificationCode(token) {
    try {
      const response = yield fetch(
        `https://api.nightwatch.io/api/v1/user/confirm_account_before_saving?token=${token}`,
        {
          method: 'POST',
        }
      );
      if (response.ok) {
        // Emit when code is submitted. Click on 'Next'.
        posthog.capture('code_confirmed', { success: true });

        this.notifications.success('Your email has been verified.', {
          autoClear: true,
        });

        this.router.transitionTo('signup-form', token);
      } else {
        // Emit when code is submitted. Click on 'Next'.
        posthog.capture('code_confirmed', { success: false });
        // Handle non-200 responses
        this.notifications.error('Error verifying email. Please try again.', {
          autoClear: true,
        });
      }
    } catch (e) {
      console.log(e);
      this.notifications.error('Error verifying email. Please try again.', {
        autoClear: true,
      });
    }
  }

  @task({ drop: true })
  *updateUser(data, isSubUser = false) {
    try {
      const response = yield fetch(
        `${config.apiBaseURL}user/update_account_info?token=${data.token}&subuser=${isSubUser}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // analytics area
        this.collectSignupInfo.perform(data);
        this.setNeedsOnboarding(this.siteData.isWhiteLabel);
        this.trackConversion(this.siteData.isWhiteLabel);

        // Emit when signup is completed. Click on 'Finish'.
        posthog.capture('signup_completed', { success: true });

        this.notifications.success('Your account has been created.', {
          autoClear: true,
        });
        const { auth_token } = yield response.json();
        this.session.loadUser.perform(auth_token);
      } else {
        // Emit when signup is completed. Click on 'Finish'.
        posthog.capture('signup_completed', { success: false });
        // Handle non-200 responses
        this.notifications.error(
          (yield response.json()).error ??
            'Error creating account. Please try again.',
          {
            autoClear: true,
          }
        );
      }
    } catch (e) {
      console.log(e);
      this.notifications.error('Error creating account. Please try again.', {
        autoClear: true,
      });
    }
  }

  @task({ drop: true })
  *resendVerificationEmail(email) {
    try {
      const url = `${config.apiBaseURL}user/resend_confirmation_email?email=${
        this.userEmail ?? email
      }`;
      const response = yield fetch(url, {
        method: 'POST',
      });
      if (response.ok) {
        this.notifications.success(
          'The email has been sent. Please check your inbox.',
          {
            autoClear: true,
          }
        );
      } else {
        // Handle non-200 responses
        this.notifications.error('Error resending email. Please try again.', {
          autoClear: true,
        });
      }
    } catch (e) {
      console.log(e);
      this.notifications.error('Error resending email. Please try again.', {
        autoClear: true,
      });
    }
  }

  @task
  *collectSignupInfo(user) {
    if (isTesting) return;
    //https://hooks.zapier.com/hooks/catch/4790508/bidklgp/
    yield fetch('https://hooks.zapier.com/hooks/catch/4790508/bidklgp/', {
      method: 'POST',
      body: JSON.stringify(user),
    });
  }

  setNeedsOnboarding(isWhitelabel) {
    if (isWhitelabel) return;
    localStorage.setItem('nw:needs-onboarding', JSON.stringify(true));
  }

  trackConversion(isWhitelabel) {
    // Picked up after login so that "Registered" event can be tracked by all adapters
    this.session.trackRegistered = true;
    if (isWhitelabel) return;
    try {
      window?.ga('send', {
        hitType: 'event',
        eventCategory: 'Conversions',
        eventAction: 'register',
        eventLabel: '',
      });

      window?.fbq('track', 'Lead');

      trackUnbounceConversion();
    } catch {
      // Swallow
    }
  }
}

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { WEEKLY } from 'nightwatch-web/constants/time-periods';

export default class DashboardUrlReportsNewRoute extends Route {
  @service metrics;
  @service store;

  activate() {
    window.scrollTo(0, 0);
  }

  model(params) {
    const dynamicView = params.viewId
      ? this.store.findRecord('dynamic-view', params.viewId)
      : null;
    const name =
      this.get('dynamicView.name') ||
      this.modelFor('dashboard.url').get('friendlyUrl');
    const report = this.store.createRecord('report', {
      url: this.modelFor('dashboard.url'),
      scheduled: false,
      schedule_interval: WEEKLY,
      day_in_month: 1,
      local_report_day: 0,
      local_report_hour: 12,
      recipients: [''],
      graph_ids: [],
      report_format: 'pdf',
      date_range_type: 'week',
      name: `Report for ${name}`,
    });
    report.setEmailParams();

    return hash({
      dynamicView,
      url: this.modelFor('dashboard.url'),
      report,
      whitelabelLogos: this.store.findAll('whitelabel-logo'),
    });
  }

  afterModel(model) {
    if (model.dynamicView) {
      model.report.set('dynamicView', model.dynamicView);
      const reportName = `${model.dynamicView.get('name')} Report`;
      model.report.set('name', reportName);
    }
    let defaultLogo;
    if ((defaultLogo = model.whitelabelLogos.findBy('default', true))) {
      model.report.set('whitelabel_logo_id', defaultLogo.id);
    } else {
      model.report.set('whitelabel_logo_id', -1);
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    model.url.loadRelationships.perform();
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Create Report' });
    return true;
  }

  renderTemplate() {
    this.render({
      into: 'dashboard',
    });
  }
}

import Model, { attr, hasMany } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import { resolve } from 'rsvp';
import { capitalize } from '@ember/string';
import shiftToUTC from 'nightwatch-web/utils/shift-to-utc';
import {
  differenceInDays,
  intervalToDuration,
  isBefore,
  parseISO,
  isSameDay,
} from 'date-fns';

export default class User extends Model {
  @attr('string') email;
  @attr('string') registration_email;
  @attr('string') password;
  @attr('string') password_confirm;
  @attr('string') current_password;
  @attr('number') url_limit;
  @attr('string') phone_number;
  @attr('string') company_name;
  @attr('number') urls_remaining;
  @attr('number') keyword_limit;
  @attr('number') account_keyword_count;
  @attr('number') keywords_remaining;
  @attr('string') timezone;
  @attr('number') report_hour;
  @attr('number') report_interval;
  @attr('string') name;
  @attr('boolean') is_company;
  @attr('boolean') send_invoice_emails;
  @attr('string') invoice_name;
  @attr('string') invoice_address;
  @attr('string') invoice_state;
  @attr('string') invoice_city;
  @attr('string') invoice_postcode;
  @attr('string') invoice_country;
  @attr('string') invoice_vat_number;
  @attr('string') invoice_email;
  @attr('number') competitors_per_url;
  @attr('boolean') nightwatchSignup;
  @attr('number', { readOnly: true }) accountId;
  @attr() account_access;
  @attr() account_info;
  @attr() settings;
  @attr() user_info;
  @attr() pinnedItemsOrder;
  @attr('string') signupToken; // for whitelabel signup?
  @attr('string', { readOnly: true }) google_oauth_refresh_token;
  @attr('date', { readOnly: true }) google_analytics_updated_at;
  @attr('string') plan_name; // for signup to specific plans (beta_trial)
  @attr('string') mixpanelDistinctId;
  @attr('date') userCreatedAt;
  @attr('date') accountCreatedAt;
  @attr('date') announcement_seen_at;
  @attr('boolean', { readOnly: true }) agency;
  @attr('boolean', { readOnly: true }) confirmed;
  @attr('boolean') backlinksEnabled;
  @attr('number') backlinksLimit;
  @attr('number') backlinksCount;
  @attr() consents;
  @attr('string') api_access_token;
  @attr('number') siteAuditMaxUrls;
  @attr() subscriptionAmounts;
  @attr('string') paymentGateway;

  // User notifications
  @attr('date') last_notifications_read_at;
  @attr('number') new_user_notifications_count;

  // Billing & subscription
  @attr() plan;
  @attr('date') next_payment_due_on;
  @attr('string') status;
  @attr() payment_info;
  @attr('date') active_until;
  @attr('boolean') european_union_member;

  // site meta
  @attr() site;

  @hasMany('url', { async: true }) urls;
  @hasMany('url-group', { async: true }) urlGroups;
  @hasMany('whitelabel-domain', { async: false }) whitelabelDomains;

  get createdAt() {
    return this.userCreatedAt;
  }

  get canAddKeywords() {
    return this.account_access?.adding_keywords;
  }

  get canAddUrls() {
    return this.account_access?.adding_urls;
  }

  get canAccessApi() {
    return this.account_access?.can_access_api;
  }

  get isAdmin() {
    return this.account_access?.admin;
  }

  get isOwner() {
    return this.account_access?.owner;
  }

  get siteAuditEnabled() {
    return this.siteAuditMaxUrls > 0;
  }

  get stripeCustomer() {
    return this.paymentGateway === 'stripe';
  }

  get braintreeCustomer() {
    return this.paymentGateway === 'braintree';
  }

  get paypalCustomer() {
    return this.paymentGateway === 'paypal';
  }

  get missingBillingInfo() {
    if (this.is_company && this.european_union_member) {
      return (
        isBlank(this.invoice_name) ||
        isBlank(this.invoice_address) ||
        isBlank(this.invoice_city) ||
        isBlank(this.invoice_postcode)
      );
    } else {
      return false;
    }
  }

  // GA
  get googleAnalyticsEnabled() {
    return this.google_oauth_refresh_token != null;
  }

  get availableBacklinks() {
    const count = this.backlinksCount || 0;
    const limit = this.backlinksLimit || 0;
    const remaining = limit - count;
    return remaining > 0 ? remaining : 0;
  }

  get isOnMonthlyPlan() {
    return this.plan?.settings?.period_name === 'monthly';
  }

  get subscriptionEndDaysDiff() {
    return differenceInDays(this.next_payment_due_on, new Date());
  }

  get didNotDoTrial() {
    // This assumes the user did not go through the trial because they created an account
    // and did not enter a card into stripe, backing out of the purchase and not starting
    // the trial, this should have occurred on the same day. We also want to expose this
    // to the window so the nw-pricing-widget-v2 can read this value to show trial text.
    return isSameDay(this.accountCreatedAt, this.active_until);
  }

  get refundAmount() {
    if (!this.isOnMonthlyPlan) return 0;
    const price = this.plan?.settings?.price;
    const daysDiff = this.subscriptionEndDaysDiff;
    return daysDiff ? (daysDiff * price) / 30 : 0;
  }

  get isTrial() {
    return this.status === 'trial';
  }

  get isPaying() {
    return this.status === 'paying';
  }

  get isExpired() {
    return this.status === 'expired';
  }

  get isFree() {
    return this.status === 'free';
  }

  get isCancelled() {
    return this.status === 'cancelled';
  }

  get simplePlanName() {
    return capitalize(this.plan?.settings?.display_name).split(' ')[0];
  }

  get fullPlanName() {
    return this.plan?.settings?.display_name;
  }

  get isInactive() {
    return this.active_until < Date.now();
  }

  get hasPaymentProblem() {
    const daysDiff = differenceInDays(this.next_payment_due_on, new Date());
    // Due is in the past
    return this.isPaying && daysDiff <= -1;
  }

  get backlinksRemaining() {
    const remaining = this.backlinksLimit - this.backlinksCount;
    return Math.max(remaining, 0);
  }

  get daysToInactivity() {
    const duration = intervalToDuration({
      start: new Date(),
      end: this.active_until,
    });
    return duration.days;
  }

  get isLimited() {
    return !this.isAdmin && !this.isOwner;
  }

  get keywordsUsed() {
    return this.keyword_limit - this.keywords_remaining;
  }

  get urlsUsed() {
    return this.url_limit - this.urls_remaining;
  }

  get backlinksUsed() {
    return this.backlinksLimit - this.backlinksRemaining;
  }

  // Hiding backlinks for all customers from 02 September 2021 UTC
  get backlinksVisible() {
    return isBefore(this.accountCreatedAt, shiftToUTC(parseISO('2021-09-02')));
  }

  get keywordLimitReached() {
    return (
      this.keywordsUsed >= this.keyword_limit || this.keywords_remaining === 0
    );
  }

  /*
    No-op for bug where `reload()` is called on a saving instance.
    Reloading an `inFlight` record is considered a violation by `ember-data`.

    My rationale is that we are reloading the user because we want fresh user data.
    But since a save is in progress, the API is about to respond with fresh user data anyway.
   */
  reload() {
    if (this.isSaving) return resolve(this);
    return super.reload();
  }
}

import Component from '@glimmer/component';
import { task, rawTimeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
  @class LoginFormComponent

  Login form for authentication
 */
export default class LoginFormComponent extends Component {
  @service session;
  @service router;

  @tracked email;
  @tracked password;
  @tracked showPassword = false;
  @tracked error;

  @task({ restartable: true })
  *login() {
    const { email, password } = this;
    try {
      yield this.session.login.perform({ email, password });
      /*
        If this operation succeeds, a browser redirect will be initiated
        Keep the task running for 2 seconds – the "Login" button will continue to show "Logging in..."
      */
      yield rawTimeout(2000);
    } catch (error) {
      this.error = error;
    }
  }

  @action
  resetError() {
    this.error = null;
  }
}

import Service, { inject as service } from '@ember/service';
import ENV from 'nightwatch-web/config/environment';
import loadExternalScripts from 'nightwatch-web/utils/load-external-scripts';
import { tracked } from '@glimmer/tracking';
import config from '../config/environment';

const GOOGLE_API_SCRIPT_URL = 'https://accounts.google.com/gsi/client';

export default class GoogleAnalyticsIntegrationService extends Service {
  @service fetch;
  @service notifications;
  @service errorReporter;
  @service session;

  @tracked auth = null;

  async connectGa() {
    return this.auth
      ? await this.auth.requestCode()
      : Promise.reject(
          new Error(
            'Connecting GA failed. Google API auth2 library unavailable.'
          )
        );
  }

  disableAnalytics() {
    this.fetch.post('/user/disable_google_analytics').then(() => {
      window.location.reload();
    });
  }

  async prepareGoogleClient() {
    if (this.auth) return;
    await loadExternalScripts([GOOGLE_API_SCRIPT_URL]);
    try {
      this.auth = await window.google.accounts.oauth2.initCodeClient({
        client_id: ENV.googleClientId,
        scope:
          'email profile https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/webmasters.readonly openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        callback: async (response) => {
          if (!response.code) throw new Error(response);
          await this._saveToken(response);
          window.location.reload();
        },
      });
    } catch (gapiError) {
      if (!window.google) {
        this.notifications
          .clearAll()
          .error(
            `Google's authentication script could not be loaded, do you have an adblocker enabled? If not check your internet connection or contact support.`,
            {
              autoClear: true,
              clearDuration: 10000,
            }
          );
        this.errorReporter.error(gapiError);
        return;
      }
      let message;
      if (gapiError.details?.match(/^Not a valid origin/)) {
        this.errorReporter.warning(
          `Invalid GA origin: (${window.location.host})`
        );
        message = `The domain (${window.location.host}) is not enabled for Google Analytics. Please contact support and we will enable it for you.`;
      }

      if (gapiError.details?.match(/^Cookies are not enabled/)) {
        message =
          'Cookies are required for this feature. Please enable Cookies in your browser and try again.';
      }

      if (!message) {
        // Report unhandled error
        this.errorReporter.error(gapiError);
        message =
          'Unable to connect to Google Analytics. Please contact support.';
      }

      this.notifications.clearAll().error(message, {
        autoClear: true,
        clearDuration: 10000,
      });
      return;
    }
  }

  async fetchProfiles() {
    const request = await fetch(
      `${config.apiBaseURL}ganal_profiles?access_token=${this.session.token}`
    );
    return request.json();
  }

  async _saveToken(authResult) {
    const request = await fetch(
      `${ENV.serverURL}auth/google_oauth2/callback?access_token=${this.session.token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest',
        },
        body: new URLSearchParams({
          ...authResult,
          redirect_uri: 'postmessage',
        }),
      }
    );
    if (!request.ok) {
      this.notifications.error(
        'There was a problem connecting your Google account, please contact support.',
        { autoClear: false }
      );
    } else {
      return request;
    }
  }
}

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import extend from 'lodash-es/extend';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import Rollbar from 'rollbar';

export default Component.extend({
  fetch: service(),
  subscriptionData: service(),
  siteData: service(),
  countries: alias('subscriptionData.countries'),
  countriesExtended: alias('siteData.countriesExtended'),
  didInsertElement() {
    this._super(...arguments);
    this.setupBraintree();
  },
  saveUserAndCharge: action(function () {
    this.resetErrors();
    this.setProcessing(true);
    this.setUserProperties();
    Rollbar.info(
      'Braintree: Saving user and charging',
      this.user.invoice_country
    );

    this.user
      .save()
      .then(() => {
        Rollbar.info(
          'Braintree: Successfully saved user',
          this.user.invoice_country
        );
        // makeRequest is called in the hostedFields callback
        document.getElementById('nw-paycc-submit').click();
      })
      .catch((response) => {
        Rollbar.critical('Braintree billing error', response);
        this.setProcessing(false);
        this.setUserError(response.errors[0]?.detail);
        Rollbar.critical('Did Braintree billing error complete?', response);
      });
  }),
  willDestroy() {
    this._super(...arguments);
    this.hostedFieldsInstance?.teardown();
  },
  alpha3ToAlpha2CountryCode(alpha3CountryCode) {
    return this.countriesExtended.find((c) => c.alpha3 == alpha3CountryCode).id;
  },
  makeRequest(data) {
    this.setPaymentError(null);
    this.setProcessing(true);
    this.paymentSource = data.type;

    // The country code will be in alpha3 encoding
    let actualCountryCode = data.details.countryCode; // PayPal
    if (data.binData) {
      actualCountryCode = data.binData.countryOfIssuance; // Credit Card
    }

    const isBusinessAccount =
      this.user.is_company && isPresent(this.user.invoice_vat_number);

    // In Sandbox, actualCountryCode will be "Unknown"
    // Skip country check for businesses as their billing and resident country could differ
    if (
      !isBusinessAccount &&
      actualCountryCode != null &&
      actualCountryCode != 'Unknown'
    ) {
      const actualCountryCodeAlpha2 =
        this.alpha3ToAlpha2CountryCode(actualCountryCode);
      if (actualCountryCodeAlpha2 != this.countryCode) {
        const actualCountry = this.countries.find(
          (c) => c[1] == actualCountryCodeAlpha2
        )[0];
        const providedCountry = this.country;
        const msg = `Your payment method's billing country is ${actualCountry} while you selected ${providedCountry}. Please change the billing country to ${actualCountry} to see the updated tax amount and continue with the purchase.`;
        Rollbar.critical('Braintree billing error', msg);
        this.setProcessing(false);
        return this.setPaymentError(msg);
      }
    }

    const userData = {
      country: this.countryCode,
    };

    if (this.mode === 'subscribe') {
      extend(userData, {
        plan_id: this.plan.id,
        coupon: this.coupon,
      });
    }

    extend(userData, data);

    // Only trial account upgrades count as conversions
    const isConversion = this.user.isTrial;

    const promise = this.fetch.post(`/user/${this.formId}`, {
      contentType: 'application/json',
      data: userData,
    });

    promise.then(() => {
      this.setProcessing(false);
      this.afterPaymentSuccess(isConversion, this.amount);
    });

    promise.catch((e) => {
      Rollbar.critical('Braintree billing error', e, e?.payload?.error);
      const errorMsg = e?.payload?.error;
      const msg = errorMsg
        ? `There was a problem with your payment method: ${errorMsg}. Please reload the page and try again (with a different payment method), or contact support.`
        : 'There was a problem with your payment method. Please reload the page and try again (with a different payment method), or contact support.';
      this.setProcessing(false);
      this.setPaymentError(msg);
    });
  },
  setupBraintree() {
    const submit = document.getElementById('nw-paycc-submit');
    const componentContext = this;
    let threeDSecure = null;
    const authorization = this.paymentData.token;

    window.braintree.client.create(
      {
        authorization: authorization,
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          Rollbar.critical('Braintree billing error', clientErr);
          componentContext.get('setPaymentError')(clientErr.message);
          componentContext.get('setIsProcessing')(false);
          return;
        }

        // Create hosted fields
        window.braintree.hostedFields.create(
          {
            client: clientInstance,
            styles: {
              'input.invalid': {
                color: '#ed574a',
              },
              'input.valid': {
                color: '#64d18a',
              },
            },
            fields: {
              number: {
                selector: '#nw-card-number',
                placeholder: '4111 1111 1111 1111',
              },
              cvv: {
                selector: '#nw-cvv',
                placeholder: '123',
              },
              expirationDate: {
                selector: '#nw-expiration-date',
                placeholder: '10/2019',
              },
            },
          },
          function (hostedFieldsErr, hostedFieldsInstance) {
            if (hostedFieldsErr) {
              Rollbar.error(
                'Braintree billing error',
                hostedFieldsErr,
                hostedFieldsInstance
              );
              componentContext.get('setPaymentError')(hostedFieldsErr.message);
              componentContext.get('setProcessing')(false);
              return;
            }

            componentContext.set('hostedFieldsInstance', hostedFieldsInstance);

            submit.addEventListener(
              'click',
              (e) => {
                e.preventDefault();

                hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
                  if (tokenizeErr) {
                    Rollbar.critical(
                      'Braintree billing error',
                      tokenizeErr,
                      payload
                    );
                    componentContext.get('setPaymentError')(
                      tokenizeErr.message
                    );
                    componentContext.get('setProcessing')(false);
                    return;
                  }

                  const amount = componentContext.get('amount').toFixed(2);
                  threeDSecure.verifyCard(
                    {
                      amount: amount,
                      nonce: payload.nonce,
                      bin: payload.details.bin,
                      onLookupComplete: function (data, next) {
                        // use `data` here, then call `next()`
                        next();
                      },
                    },
                    function (err, response) {
                      if (err) {
                        Rollbar.error('Braintree billing error', err, response);
                        componentContext.get('setPaymentError')(err.message);
                        componentContext.get('setProcessing')(false);
                        return;
                      }
                      componentContext.makeRequest(response);
                    }
                  );
                });
              },
              false
            );
          }
        );

        // 3D Secure for strong customer authentication
        window.braintree.threeDSecure.create(
          {
            client: clientInstance,
            version: 2,
          },
          function (threeDSecureErr, threeDSecureInstance) {
            if (threeDSecureErr) {
              Rollbar.critical('Braintree billing error', threeDSecureErr);
              componentContext.get('setPaymentError')(threeDSecureErr.message);
              componentContext.get('setProcessing')(false);
              return;
            }

            threeDSecure = threeDSecureInstance;
          }
        );
      }
    );
  },
});

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'nightwatch-web/config/environment';

export default class ConfirmAccountRoute extends Route {
  @service router;
  @service fetch;
  @service session;
  @service errorReporter;

  beforeModel() {
    this.session.requireAuthentication();
    if (this.session.user.confirmed) {
      this.router.transitionTo('dashboard.overview');
    }
  }

  afterConfirm() {
    try {
      if (window.gtag) {
        const conversionId = 'YCxmCKa472sQ_entnQM';
        window.gtag('event', 'conversion', {
          send_to: `${ENV.gtagId}/${conversionId}`,
        });
      }
      if (window.qp) {
        window.qp('track', 'CompleteRegistration');
      }
    } catch (e) {
      this.errorReporter.error(
        'Conversion tracking failed after account confirmation.',
        e
      );
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    const { token } = controller;
    if (this.controller.get('tokenPresent')) {
      this.controller.set('isConfirming', true);
      this.fetch
        .post('/user/confirm_account', { data: { token } })
        .then(() => {
          localStorage.setItem('nw:needs-onboarding', JSON.stringify(true));
          this.session.user.confirmed = true;
          this.notifications.success('E-mail confirmed succesfully.', {
            autoClear: true,
            clearDuration: 3500,
          });
          this.afterConfirm();
          this.router.transitionTo('start');
        })
        .catch((error) => {
          this.controller.set('isConfirming', false);
          const errorMessage =
            error.errors?.[0]?.title || 'Account confirmation failed.';
          controller.set('error', errorMessage);
          if (errorMessage.indexOf('already confirmed') !== -1) {
            this.router.transitionTo('dashboard.overview');
          } else {
            controller.set('errorMessage', errorMessage);
          }
        });
    }
  }

  @action
  loading() {
    return false; //Do not show loading spinner when clicking on pages
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import isDev from '../../utils/is-dev';

export default class CurrentPlanComponent extends Component {
  @service session;
  @service intercom;
  @tracked showCancelFlow = false;

  get user() {
    return this.session?.user;
  }

  get stripeCustomer() {
    return this.user?.stripeCustomer;
  }

  get showPlansLink() {
    // Show to *all* cancelled and expired customers
    if (this.user?.isCancelled || this.user?.isExpired) return true;
    // Only show to non-trial Stripe customers
    if (this.user?.isTrial && !this.stripeCustomer) return true;
    return false;
  }

  get trialMessage() {
    return this.stripeCustomer
      ? "We'll continue automatically using your saved card."
      : 'Subscribe to one of our plans to continue your service.';
  }

  @action
  toggleShowCancellationFlow() {
    if (isDev) {
      this.showCancelFlow = !this.showCancelFlow;
      return;
    }
    fetch('https://hooks.zapier.com/hooks/catch/4790508/b0gtw9l/', {
      method: 'POST',
      body: JSON.stringify({
        email: this.session.user.email,
      }),
    });
    this.showCancelFlow = !this.showCancelFlow;
  }
}

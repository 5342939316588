import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action, set } from '@ember/object';
import jstz from 'jstz';
import { startUrlRegex } from '../utils/regexes';
import posthog from 'posthog-js';

const COMPANY_TYPE_OPTIONS = [
  'Agency',
  'E-Commerce',
  'Enterprise',
  'Small Business',
  'Other',
];

const NIGHTWATCH_USE_OPTIONS = ['Rank Tracking', 'Site Audits', 'Reporting'];
const COMPANY_SIZE = [
  'Self-employed',
  '2-10',
  '11-50',
  '51-200',
  '201-500',
  '500-1000+',
];

const UNKNOWN_ERRORS = [
  {
    attribute: '',
    message: 'Something broke. Please try again or contact support.',
  },
];

/**
 @class SignupFormComponent

 Used to sign up to Nightwatch
 - As a Nightwatch customer
 - As a subuser with a `signupToken`
 - As a WhiteLabel customer with a `signupToken`
 - As a WhiteLabel customer: phone number and company name aren't shown

 @argument signupToken {String} - used for both Subuser *and* WhiteLabel signup
 @argument planId {String} - user selected plan id
 @argument personal {boolean} - whether to bypass the biz email check
 @argument paymentGateway {String} - which payment gateway the user should be provisioned with ("stripe" or null)
 @argument onSuccess {function(Promise)} - if provided a login redirect will not be iniated
 */
export default class SignupFormComponent extends Component {
  @service siteData;
  @service userData;
  @service store;
  @service session;
  @service errorReporter;
  @service('signup') signupService;
  @service router;
  @service theme;

  @tracked tosAgree = false;

  @tracked errors = [];
  @tracked page = 1;
  queryParams = ['formPage'];
  @tracked formPage = 1;
  @tracked isLoading = false;

  @tracked formPageTouches = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  };

  @tracked passwordShown = false;

  companyTypeOptions = COMPANY_TYPE_OPTIONS;
  nightwatchUseOptions = NIGHTWATCH_USE_OPTIONS;
  companySizeOptions = COMPANY_SIZE;

  @tracked userForm = [
    {
      firstName: '',
      lastName: '',
      password: '',
      phone_number: '',
      is_valid: false,
      _page_title: 'Tell us about yourself',
    },
    {
      company_name: '',
      company_url: 'https://',
      is_valid: false,
      _page_title: 'Tell us about your Company',
    },
    {
      companyType: '',
      is_valid: true,
      _page_title: 'What best describes your company?',
    },
    {
      companySize: '',
      is_valid: true,
      _page_title: 'What size is your company?',
    },
    {
      nightwatchUse: '',
      is_valid: true,
      _page_title: 'What would you like to use Nightwatch for?',
    },
  ];

  constructor(owner, args) {
    super(owner, args);
    // this.theme.switch.perform();
  }

  get signupToken() {
    return this.args.signupToken;
  }

  get isInvitation() {
    return this.args.isInvitation;
  }

  /**
   * Updates a a certain select(chip) field of the form
   * @param page {number}
   * @param field {string}
   * @param selectedType {string}
   */
  @action
  updateSelects(page, field, selectedType) {
    this.userForm[page - 1][field] = selectedType;
    this.handleUpdate();
  }

  /**
   * Return current page of the form
   * @returns {{firstName: string, lastName: string, password: string, is_valid: boolean, phone_number: string, _page_title: string}|{company_name: string, is_valid: boolean, company_url: string, _page_title: string}|{companyType: string, is_valid: boolean, _page_title: string}|{is_valid: boolean, _page_title: string, companySize: string}|{nightwatchUse: string, is_valid: boolean, _page_title: string}}
   */
  get currentPage() {
    return this.userForm[this.page - 1];
  }

  /**
   * Returns true if all  the fields on current form page are valid
   * @returns {boolean}
   */
  get isValid() {
    return this.userForm[this.page - 1]?.is_valid;
  }

  /**
   * Works as a change detection for the form fields. Updates the validity of the current form page.
   * @returns {boolean}
   */
  @action
  handleUpdate() {
    const currentPageData = this.userForm[this.page - 1];
    const validators = this.getPageValidators(this.page);

    // Running the validation logic through a helper function
    const isValid = validators.every((validator) => {
      const value = currentPageData[validator.field];
      return validator.method(value);
    });

    currentPageData.is_valid = isValid;
    this.userForm = [...this.userForm];
    return isValid;
  }

  /**
   * Returns validators based on the current page
   */
  getPageValidators(page) {
    switch (page) {
      case 1:
        return [
          { field: 'firstName', method: this.validateNameField },
          { field: 'lastName', method: this.validateNameField },
          { field: 'password', method: this.validatePassword },
        ];
      case 2:
        return [{ field: 'company_url', method: this.validateCompanyUrl }];
      default:
        return [{ field: '', method: () => true }]; // Assume true for simplicity
    }
  }

  validateNameField(value) {
    return !!value && !value.error;
  }

  validatePassword(value) {
    return !!value && value.length >= 8;
  }

  validateCompanyUrl(value) {
    const startUrlRegex = /^https?:\/\/.+$/;
    return !!value && startUrlRegex.test(value);
  }

  /**
   * handles the user phone input
   * @param phoneNumber {string}
   */
  @action
  handlePhoneUpdate(phoneNumber) {
    set(this.currentPage, 'phone_number', phoneNumber);
    this.handleUpdate();
  }

  /**
   * Returns the current page title
   * @returns {string}
   */
  get formPageTitle() {
    return this.currentPage._page_title;
  }

  get buttonText() {
    if (this.siteData.isWhiteLabel || this.args.signupToken) {
      return 'Sign Up';
    }
    return 'Start Your Trial';
  }

  get renderQuestionnaire() {
    return !this.args.isInvitation;
  }

  get displayErrors() {
    return this.errors.filter((e) => e.attribute !== 'error_meta');
  }

  get hasSecondPageErrors() {
    return !this.companyType || !this.company_size || !this.nightwatchUse;
  }

  get currentPlanId() {
    // 346 is the id of the basic free plan with 250kws
    return this.args.planId || 352;
  }

  @task({ drop: true })
  *updateUserData() {
    if (!this.isValid) return;
    this.isLoading = true;
    if (this.isInvitation) {
      try {
        const { firstName, lastName, password } = this.collectFormValues(
          this.userForm
        );
        const user = {
          token: this.signupToken,
          name: firstName + ' ' + lastName,
          password,
          timezone: jstz.determine().name(),
        };
        yield this.signupService.updateUser.perform(user, true).finally(() => {
          this.isLoading = false;
        });
      } catch (error) {
        return this.handleError(error);
      }
    } else {
      try {
        const {
          firstName,
          lastName,
          password,
          phone_number,
          company_name,
          company_url,
          companyType,
          companySize,
          nightwatchUse,
        } = this.collectFormValues(this.userForm);
        // const { signupToken, paymentGateway } = this.args;
        const user = {
          token: this.signupToken,
          name: firstName + ' ' + lastName,
          password: password,
          phone_number: phone_number,
          company_name,
          company_url,
          company_type: companyType,
          nightwatch_use: nightwatchUse,
          company_size: companySize,
          timezone: jstz.determine().name(),
        };
        yield this.signupService.updateUser.perform(user, false).finally(() => {
          this.isLoading = false;
        });
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  handleError(error) {
    // Handle known errors such as front-end and back-end validations
    if (Array.isArray(error)) {
      this.errors = error;
      return;
    }
    // Handle ember-data errors
    if (error.isAdapterError) {
      this.errors = error.errors.map((e) => ({
        attribute: '',
        message: e.title,
      }));
    } else if (error.message) {
      this.errors = [{ attribute: '', message: error.message }];
    } else {
      this.errors = UNKNOWN_ERRORS;
    }

    this.errorReporter.error('Sign up failed', {
      message: error.message,
      stack: error.stack,
    });
    this.page = 1;
  }

  /**
   * Collects the form values into a single flattened object, removing utility fields.
   * @param userForm
   * @returns {[key: string]: string | boolean | number | null }
   */
  collectFormValues(userForm) {
    return userForm.reduce((acc, curr) => {
      const { is_valid, ...rest } = curr;
      return { ...acc, ...rest };
    }, {});
  }

  @action
  resetErrors() {
    this.errors = [];
  }

  get firstNameError() {
    if (this.formTouched && !this.currentPage.firstName) {
      return 'First name is required';
    }
    return false;
  }

  get lastNameError() {
    if (this.formTouched && !this.currentPage.lastName) {
      return 'Last name is required';
    }
    return false;
  }

  get companyUrlError() {
    if (!this.formTouched) return false;
    if (!this.currentPage.company_url) {
      return 'Company URL is required';
    } else if (!this.currentPage.company_url.match(startUrlRegex)) {
      return 'Company URL is invalid';
    }

    return false;
  }

  get passwordError() {
    if (!this.formTouched) return false;

    if (!this.currentPage.password) {
      return 'Password is required';
    }

    const errorMessages = [];
    const validators = [
      { regex: /.{8,}/, message: 'be at least 8 characters long' },
      { regex: /[A-Z]/, message: 'include at least one uppercase letter' },
      { regex: /\d/, message: 'include at least one number' },
    ];

    validators.forEach(({ regex, message }) => {
      if (!regex.test(this.currentPage.password)) {
        errorMessages.push(message);
      }
    });

    if (errorMessages.length > 0) {
      return 'Password must ' + errorMessages.join(', ');
    }

    return false;
  }

  get formTouched() {
    return this.formPageTouches[this.page];
  }

  @action
  touchForm() {
    this.formPageTouches = { ...this.formPageTouches, [this.page]: true };
  }

  @action
  changePage(page) {
    switch (this.page) {
      case 1:
        if (
          !this.passwordError &&
          !this.firstNameError &&
          !this.lastNameError
        ) {
          this.page = page;
          posthog.capture('info_filled');
        }
        break;
      case 2:
        if (!this.companyUrlError) {
          this.page = page;
        }
        break;
    }
  }

  @action
  togglePasswordVisibility() {
    this.passwordShown = !this.passwordShown;
  }
}

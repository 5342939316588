import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { startUrlRegex } from '../utils/regexes';

export default class OnboardingController extends Controller {
  @service store;
  @service onboardingLocalStorage;
  @service router;
  @service session;
  @service metrics;
  @service discovery;
  @service notifications;
  @service saveKeywords;
  @service saveCompetitors;
  @service siteData;
  @tracked url = null;
  @tracked urlId = null;
  @tracked keywordTasks;
  @tracked urlGroups;
  @tracked groupId = null;
  @tracked currentStep = 'url';
  @tracked keywordPreciseLocation = null;

  queryParams = ['currentStep', 'urlId', 'groupId'];

  steps = {
    url: 1,
    target: 2,
    import: 3,
    keywords: 4,
    competitors: 5,
  };

  @action
  onInsert() {
    if (!this.urlId && this.currentStep !== 'url') {
      this.currentStep = 'url';
    }
  }

  @action
  enteredRoute(
    url,
    urlGroups,
    keywordPreciseLocation,
    device,
    engine,
    language_code,
    country_code,
    groupId
  ) {
    this.urlGroups = urlGroups;
    this.url = url;

    if (url) {
      if (keywordPreciseLocation) {
        this.keywordPreciseLocation = keywordPreciseLocation;
      }

      if (language_code) {
        this.url.language_code = language_code;
      }

      if (device) {
        this.url.device = device;
      }

      if (engine) {
        this.url.engine = engine;
      }

      if (country_code) {
        this.url.country_code = country_code;
      }

      if (groupId) {
        this.groupId = groupId;
      }
    }
  }

  get isSaving() {
    return (
      this.createUrlTask.isRunning ||
      this.saveKeywords.saveKeywordsTask.isRunning ||
      this.saveCompetitors.saveCompetitorsTask.isRunning ||
      this.saveUrlTask.isRunning
    );
  }

  get urlGroup() {
    return (
      this.urlGroups?.findBy('id', this?.groupId) ?? this.urlGroups.firstObject
    );
  }

  get whiteLabelLogoUrl() {
    if (this.siteData.isWhiteLabel) return this.siteData.siteLogoUrl;
    return null;
  }

  @action
  onClickNext() {
    switch (this.currentStep) {
      case 'url':
        // this.currentStep = 'target';
        if (!this.url.url) {
          this.notifications.error('Please enter a URL');
          return;
        }
        this.createUrlTask.perform();
        break;
      case 'target':
        this.currentStep = 'import';
        this.updateUrlTargetTask.perform();
        break;
      case 'import':
        this.currentStep = 'keywords';
        break;
      case 'keywords':
        this.saveKeywords.saveKeywordsTask.perform(() => {
          this.currentStep = 'competitors';
        });
        break;
      case 'competitors':
        this.saveCompetitors.saveCompetitorsTask.perform(
          () => {
            this.onFinishOnboarding();
          },
          false,
          this.url
        );
        break;
      default:
        this.saveUrlTask.perform();
    }
  }

  @action
  onClickBack() {
    switch (this.currentStep) {
      case 'target':
        this.currentStep = 'url';
        break;
      case 'import':
        this.currentStep = 'target';
        break;
      case 'keywords':
        this.currentStep = 'import';
        break;
      case 'competitors':
        this.currentStep = 'keywords';
        break;
    }
  }

  @task({ restartable: true })
  *saveUrlTask() {
    this.url.urlGroup = this.urlGroup;

    try {
      yield this.url.save();
      this.currentStep = this.nextStep;
      return true;
    } catch {
      this.saveUrlErrors();
    }
  }

  @task({ restartable: true })
  *updateUrlTargetTask() {
    try {
      const language_code = this.onboardingLocalStorage.urlLanguageCode || this.url.language_code;
      const country_code = this.onboardingLocalStorage.urlCountryCode || this.url.country_code
      const device = this.onboardingLocalStorage.urlDevice || this.url.device;
      const engine = this.onboardingLocalStorage.urlEngine || this.url.engine;
      const keywordPreciseLocation = this.onboardingLocalStorage.keywordPreciseLocation || this.keywordPreciseLocation || null;

      //update this url with the new target
      this.url.language_code = language_code;
      this.url.country_code = country_code;
      this.url.device = device;
      this.url.engine = engine;
      this.keywordPreciseLocation = keywordPreciseLocation;
      yield this.url.save();

    } catch (e) {
      console.log(e)
    }
  }

  @task({ restartable: true })
  *createUrlTask() {
    const newUrl = this.url.url;

    // If user goes back to initial page (currentStep="url")
    // and types in new address, we need to create new url in Store
    if (
      this.onboardingLocalStorage.url &&
      this.onboardingLocalStorage.url !== newUrl
    ) {
      const countryCode = this.url.country_code;
      const languageCode = this.url.language_code;

      this.url = this.store.createRecord('url', {
        matchNestedUrls: true,
        matchSubdomains: false,
      });
      this.url.url = newUrl;
      this.urlId = undefined;
      this.url.language_code = languageCode;
      this.url.country_code = countryCode;
    }
    const saved = yield this.saveUrlTask.perform();
    if (!saved) return;

    localStorage.removeItem('nw:needs-onboarding');
    this.session.user?.reload();
    this.urlId = this.url.id;
    this.trackAddedUrl();

    const urlData = {
      urlId: this.urlId,
      groupId: this.urlGroup.id,
      url: this.url.url,
    };
    if (this.onboardingLocalStorage.hasUrlChanged(urlData)) {
      this.keywordPreciseLocation = null;
      this.url.device = undefined;
      this.url.engine = undefined;
      this.onboardingLocalStorage.setTargetDevice();
      this.onboardingLocalStorage.setTargetEngine();
      this.onboardingLocalStorage.setTargetKeywordPreciseLocation();
    }
    this.onboardingLocalStorage.setUrl(urlData);
    this.onboardingLocalStorage.setTargetCountryCode(this.url.country_code);
    this.onboardingLocalStorage.setTargetLanguageCode(this.url.language_code);
  }

  get nextStep() {
    const nextStep = Object.keys(this.steps).indexOf(this.currentStep) + 1;
    return Object.keys(this.steps)[nextStep];
  }

  get previousStep() {
    const prevStep = Object.keys(this.steps).indexOf(this.currentStep) - 1;
    return Object.keys(this.steps)[prevStep];
  }

  get domain() {
    return this.url.domain;
  }

  get faviconDomain() {
    const domainString = this.domain?.split('.');
    if (domainString?.length > 1 && domainString?.[1].length > 0) {
      return this.url.domain;
    } else {
      return '';
    }
  }

  onFinishOnboarding() {
    this.discovery.competitors = [];
    this.discovery.keywords = [];
    this.urlId = null;
    this.groupId = null;
    this.router.transitionTo('dashboard.url.keywords', this.url.id);
  }

  trackAddedUrl() {
    this.metrics.trackEvent({
      event: 'Added URLs',
      urls: this.url.url,
    });
  }

  validateUrl(url) {
    return !!url.match(startUrlRegex);
  }

  saveUrlErrors() {
    // Don't show notification if the API responded with a validation error
    // In this case url will be invalid and invalid fields will be shown in the UI
    if (!this.url.isValid && this.url.errors.length) return;

    this.notifications.error(
      'Something went wrong saving the URL. Please contact support',
      { autoClear: true }
    );
  }

  @action
  updateUrlGroups(urlGroups) {
    this.urlGroups = urlGroups;
  }

  @action
  updateGroupId({ id: groupId }) {
    this.groupId = groupId;
    this.onboardingLocalStorage.setUrlGroupId(groupId);
  }

  @action
  updateKeywordPreciseLocation(newKeywordPreciseLocation) {
    this.keywordPreciseLocation = newKeywordPreciseLocation;
  }
}

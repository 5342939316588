import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import isDev from '../../utils/is-dev';
import { addDays } from 'date-fns';
import { task } from 'ember-concurrency';

export default class NwCancellationFlowComponent extends Component {
  @service session;
  @service fetch;
  @service intercom;
  @service stripe;
  @service notifications;
  @tracked step = 1;
  @tracked cancellationDetails = '';

  helpSelected = '';
  cancellationReason = '';
  cancellationReasons = [
    {
      text: 'Low on budget',
      icon: 'icon-money',
      stripeReason: 'too_expensive',
    },
    { text: 'Technical issues', icon: 'icon-tool', stripeReason: 'other' },
    {
      text: 'Missing feature',
      icon: 'icon-sliders',
      stripeReason: 'missing_features',
    },
    {
      text: 'Problems understanding the tool',
      icon: 'icon-question',
      stripeReason: 'too_complex',
    },
    {
      text: 'Customer service was less than expected',
      icon: 'icon-support',
      stripeReason: 'customer_service',
    },
    {
      text: 'Quality was less than expected',
      icon: 'icon-settings',
      stripeReason: 'low_quality',
    },
    {
      text: 'I’m switching to a different service',
      icon: 'icon-login',
      stripeReason: 'switched_service',
    },
    {
      text: 'I don’t use the service enough',
      icon: 'icon-changelog',
      stripeReason: 'unused',
    },
    { text: 'Other', icon: 'icon-info', stripeReason: 'other' },
  ];
  cancellationHelp = [
    { text: 'I would like a custom plan offer', icon: 'icon-money' },
    { text: 'I need help with a technical issue', icon: 'icon-tool' },
    { text: 'I need help using the tool', icon: 'icon-sliders' },
    {
      text: "I want to speak to someone about the issues I'm having",
      icon: 'icon-question',
    },
    { text: 'No thanks, I want to cancel', icon: 'icon-cross' },
  ];
  pleaseExplainHeaders = [
    'Are you trying to cut cost?',
    'What technical issues were you having?',
    'What features are you missing?',
    'What were you struggling to understand?',
    'What is the reason for cancelling your plan?',
  ];

  get allowContinue() {
    // Dictates whether the next button on the feedback step can be clicked
    if (isDev) return false;
    return [...this.cancellationDetails].length < 20;
  }

  get activeUntilWithGracePeriod() {
    const gracePeriod = 14;
    const activeUntil = this.session.user
      ? this.session.user.active_until
      : new Date();
    return addDays(activeUntil, gracePeriod).toDateString();
  }

  get pleaseExplainHeader() {
    return this.pleaseExplainHeaders[
      this.cancellationReasons.indexOf(this.cancellationReason || 'Other')
    ];
  }

  @action
  selectCancellationReason(reason) {
    this.cancellationReason = reason;
    this.step = 2;
  }

  @action
  handleLastStep(help) {
    this.helpSelected = help;
    if (help === this.cancellationHelp[4].text) {
      this.step = 4;
      return;
    }
    const intercomMessage = `Hi, ${help} \n\n${this.cancellationDetails}`;
    if (!window.Intercom) {
      return window.open('https://nightwatch.io/contact', '_blank').focus();
    }
    this.intercom.showNewMessageWithPrepopulatedMessage(intercomMessage);
    this.args.toggleShowCancellationFlow();
    this.collectCancellationData(true, false);
  }

  @task({ drop: true })
  *cancelPlanTask() {
    try {
      const stripeReason = this.cancellationReasons.find(
        (r) => r.text === this.cancellationReason
      ).stripeReason;
      yield this.fetch.request('/stripe/cancel_subscription', {
        method: 'POST',
        data: {
          comment: this.cancellationDetails,
          feedback: stripeReason,
        },
      });
      this.collectCancellationData(false, false);
      this.args.toggleShowCancellationFlow();
      this.notifications.success('Your plan has been successfully cancelled.', {
        autoClear: true,
        clearDuration: 5000,
      });
    } catch (e) {
      this.notifications.error(
        'Error cancelling your plan. Please try again or contact our support.',
        { autoClear: true, clearDuration: 5000 }
      );
      this.args.toggleShowCancellationFlow();
    }
  }

  @action
  collectCancellationData(openedIntercom, wentToStripe) {
    if (isDev) return;
    const user = this.session.user;
    fetch('https://hooks.zapier.com/hooks/catch/4790508/b0gdqqi/', {
      method: 'POST',
      body: JSON.stringify({
        email: user.email,
        name: user.name,
        reason: this.cancellationReason,
        details: this.cancellationDetails,
        helpSelected: this.helpSelected,
        planName: user.fullPlanName,
        planId: user.plan?.settings?.id,
        planPeriod: user.plan?.settings?.period_name,
        planPrice: user.plan?.settings?.price,
        userCreatedAt: user.userCreatedAt,
        userId: user.id,
        keywordsUsed: user.keywordsUsed,
        keywordLimit: user.keyword_limit,
        keywordsRemaining: user.keywords_remaining,
        urlsUsed: user.urlsUsed,
        userSignupInfo: user.user_info?.signup_questionnaire?.map(
          (q) => `${q.question} => ${q.answer}`
        ),
        openedIntercom,
        wentToStripe,
      }),
    });
  }
}

import Service, { inject as service } from '@ember/service';
import humanize from 'nightwatch-web/utils/humanize';
import titleize from 'nightwatch-web/utils/titleize';
import fillGaps from '../../utils/chart/fill-gaps';
import clampData from '../../utils/chart/clamp-data';
import { groupData, groupMethods } from '../../utils/chart/grouping';
import {
  channelDistributionColors,
  newReturningColors,
} from '../../components/nw-traffic-overview';

/**
  Responsible for series data for the *new* Chart.js graphs
 */
export default class NwGraphSeriesService extends Service {
  @service graphColorManager;

  averagePositionSeries(data, grouping, interval) {
    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.average
    );

    return [
      {
        data: data.map(([date, value]) => ({ x: date, y: value })),
        label: 'Average Position',
        scaleId: 'nwPosition',
        type: 'nwLine',
        borderColor: '#89d63a',
      },
    ];
  }

  searchVisibilitySeries(seriesName, data, grouping, interval) {
    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.average
    );

    return [
      {
        backgroundColor: 'rgb(39, 177, 78)',
        data: data.map(([date, value]) => ({ x: date, y: value })),
        label: titleize(humanize(seriesName.replace('_series', ''))),
        scaleId: 'nwValue',
        type: 'nwLine',
        borderColor: '#89d63a',
      },
    ];
  }

  distributionSeries(data, grouping, interval) {
    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.sum
    );

    // for new urls without processed keywords, the response is [[null, {}]]
    if (data?.[0]?.[0] == null) data = [];

    return [
      {
        backgroundColor: this.graphColorManager.colorForSeries('top_3'),
        borderColor: 'rgba(0, 0, 0, 0.1)',
        data: data.map(([date, value]) => ({ x: date, y: value?.top_3 })),
        label: 'Top 3',
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        backgroundColor: this.graphColorManager.colorForSeries('top_10'),
        borderColor: 'rgba(0, 0, 0, 0.1)',
        data: data.map(([date, value]) => ({ x: date, y: value?.top_10 })),
        label: 'Top 10',
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        backgroundColor: this.graphColorManager.colorForSeries('top_100'),
        borderColor: 'rgba(0, 0, 0, 0.1)',
        data: data.map(([date, value]) => ({ x: date, y: value?.top_100 })),
        label: 'Top 100',
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
        borderColor: 'rgba(0, 0, 0, 0.1)',
        data: data.map(([date, value]) => ({ x: date, y: value?.no_rank })),
        label: 'No Rank',
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
    ];
  }

  singleDistributionSeries(keywordStats) {
    return [
      {
        label: 'Top 3',
        data: keywordStats?.top3_count,
        backgroundColor: this.graphColorManager.colorForSeries('top_3'),
      },
      {
        label: 'Top 10',
        data: keywordStats?.top10_count,
        backgroundColor: this.graphColorManager.colorForSeries('top_10'),
      },
      {
        label: 'Top 100',
        data: keywordStats?.top100_count,
        backgroundColor: this.graphColorManager.colorForSeries('top_100'),
      },
      {
        label: 'No Rank',
        data: keywordStats?.no_rank_count,
        backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
      },
    ];
  }

  upDownSeries(data, grouping, interval) {
    // New urls without processed keywords the response is [[null, {}]]
    const firstDate = data?.[0]?.[0];
    data = firstDate === null ? [] : data;

    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.sum
    );

    return [
      {
        backgroundColor: '#89d63a',
        data: data.map(([date, value]) => ({ x: date, y: value?.went_up })),
        label: 'Up',
        scaleId: 'nwValue',
        type: 'nwBar',
      },
      {
        backgroundColor: '#d8232e',
        data: data.map(([date, value]) => ({ x: date, y: -value?.went_down })),
        label: 'Down',
        scaleId: 'nwValue',
        type: 'nwBar',
      },
    ];
  }

  // Backlinks Series Functions
  // TODO: Remove after backlinks sunset
  singleBacklinkQualitySeries(data) {
    return [
      {
        label: 'High',
        data: data?.high_quality,
        backgroundColor: this.graphColorManager.colorForSeries('top_3'),
      },
      {
        label: 'Medium',
        data: data?.medium_quality,
        backgroundColor: this.graphColorManager.colorForSeries('top_10'),
      },
      {
        label: 'Low',
        data: data?.low_quality,
        backgroundColor: this.graphColorManager.colorForSeries('top_100'),
      },
      {
        label: 'Suspicious',
        data: data?.suspicious_quality,
        backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
      },
    ];
  }

  backlinkSeries(data, grouping, interval) {
    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.average
    );

    return [
      {
        data: data.map(([date, value]) => ({ x: date, y: value })),
        label: 'Active Backlinks',
        scaleId: 'nwValue',
        type: 'nwLine',
        borderColor: '#89d63a',
      },
    ];
  }

  backlinkDiversitySeries(seriesName, data, grouping, interval) {
    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.average
    );

    return [
      {
        borderColor: 'rgb(39, 177, 78)',
        data: data.map(([date, value]) => ({ x: date, y: value })),
        label: titleize(humanize(seriesName.replace('_series', ''))),
        scaleId: 'nwValue',
        type: 'nwLine',
      },
    ];
  }

  backlinkQualitySeries(data, grouping, interval) {
    const firstDate = data?.[0]?.[0];
    data = firstDate === null ? [] : data;

    const high = data?.high_quality_series ?? [];
    const med = data?.medium_quality_series ?? [];
    const low = data?.low_quality_series ?? [];
    const sus = data?.suspicious_quality_series ?? [];

    data = high.map((e, index) => [
      e[0],
      {
        high: high[index]?.[1],
        medium: med[index]?.[1],
        low: low[index]?.[1],
        suspicious: sus[index]?.[1],
      },
    ]);

    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.sum
    );

    return [
      {
        label: 'High',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.high,
        })),
        backgroundColor: this.graphColorManager.colorForSeries('top_3'),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Medium',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.medium,
        })),
        backgroundColor: this.graphColorManager.colorForSeries('top_10'),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Low',
        data: data.map(([date, value]) => ({ x: date, y: value.low })),
        backgroundColor: this.graphColorManager.colorForSeries('top_100'),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Suspicious',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.suspicious,
        })),
        backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
    ];
  }

  backlinksNewLostSeries(data, grouping, interval) {
    const firstDate = data?.[0]?.[0];
    data = firstDate === null ? [] : data;

    const newSeries = data?.new_series ?? [];
    const lostSeries = data?.lost_series ?? [];

    data = newSeries?.map((e, index) => [
      e[0],
      {
        newSeries: newSeries[index][1],
        lostSeries: lostSeries[index][1],
      },
    ]);

    data = groupData(
      fillGaps(clampData(data, interval)),
      grouping,
      groupMethods.sum
    );

    return [
      {
        backgroundColor: '#89d63a',
        data: data.map(([date, value]) => ({ x: date, y: value.newSeries })),
        label: 'New',
        scaleId: 'nwValue',
        type: 'nwBar',
      },
      {
        backgroundColor: '#d8232e',
        data: data.map(([date, value]) => ({ x: date, y: -value.lostSeries })),
        label: 'Lost',
        scaleId: 'nwValue',
        type: 'nwBar',
      },
    ];
  }

  trafficAcquisitionSeries(data) {
    return [
      {
        label: 'Direct',
        data: data?.direct_sum ?? 0,
        backgroundColor: channelDistributionColors.direct,
        scaleId: 'nwStackedValue',
      },
      {
        label: 'Organic Search',
        data: data?.organic_sum ?? 0,
        backgroundColor: channelDistributionColors.organic,
        scaleId: 'nwStackedValue',
      },
      {
        label: 'Social',
        data: data?.social_sum ?? 0,
        backgroundColor: channelDistributionColors.social,
        scaleId: 'nwStackedValue',
      },
      {
        label: 'Referral',
        data: data?.referral_sum ?? 0,
        backgroundColor: channelDistributionColors.referral,
        scaleId: 'nwStackedValue',
      },
      {
        label: 'Paid',
        data: data?.ads_sum ?? 0,
        backgroundColor: channelDistributionColors.paid,
        scaleId: 'nwStackedValue',
      },
      {
        label: 'Other (Email, Afilliates,...)',
        data: data?.other_sum ?? 0,
        backgroundColor: channelDistributionColors.other,
        scaleId: 'nwStackedValue',
      },
    ];
  }

  acqusitionSeries(data, grouping, interval) {
    const transform = (seriesData) => {
      seriesData ??= [];
      seriesData = groupData(
        fillGaps(clampData(seriesData, interval)),
        grouping,
        groupMethods.sum
      );
      return seriesData.map(([dateStr, value]) => {
        return { x: dateStr, y: value };
      });
    };

    return [
      {
        label: 'Direct',
        backgroundColor: channelDistributionColors.direct,
        data: transform(data?.direct_series),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Organic Search',
        backgroundColor: channelDistributionColors.organic,
        data: transform(data?.organic_series),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Social Networks',
        backgroundColor: channelDistributionColors.social,
        data: transform(data?.social_series),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Referral Sites',
        backgroundColor: channelDistributionColors.referral,
        data: transform(data?.referral_series),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Paid',
        backgroundColor: channelDistributionColors.ads,
        data: transform(data?.ads_series),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        label: 'Other (Email, Affilliates,...)',
        backgroundColor: channelDistributionColors.other,
        data: transform(data?.other_series),
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
    ];
  }

  newReturningSeries(data, grouping, interval) {
    if (!data.new_users_series || !data.returning_users_series) return [];

    const transform = (seriesData) => {
      return groupData(
        fillGaps(clampData(seriesData, interval)),
        grouping,
        groupMethods.sum
      ).map(([dateStr, value]) => ({
        x: dateStr,
        y: value,
      }));
    };

    return [
      {
        data: transform(data.new_users_series),
        backgroundColor: newReturningColors.new,
        label: 'New Visitors',
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
      {
        data: transform(data.returning_users_series),
        backgroundColor: newReturningColors.returning,
        label: 'Returning Visitors',
        scaleId: 'nwStackedValue',
        type: 'nwBar',
      },
    ];
  }
}

import isTesting from 'nightwatch-web/utils/is-testing';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import md5 from 'js-md5';
import { task, rawTimeout } from 'ember-concurrency';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';
import { tracked } from '@glimmer/tracking';
import posthog from 'posthog-js';

const AFTER_LOGOUT_URL = 'login';

const TOKEN_KEY = 'nw:session:token';
const FROM_ADMIN_KEY = 'nw:session:from-admin';
const TRACK_REGISTERED_KEY = 'nw:session:track-registered';

const REQUIRE_AUTHENTICATION_ROUTE = 'login';
const PROHIBIT_AUTHENTICATION_ROUTE = 'dashboard.overview';

export default class SessionService extends Service {
  @service store;
  @service siteData;
  @service metrics;
  @service intercom;
  @service fetch;
  @service router;
  @service theme;

  @tracked user;

  get isAuthenticated() {
    return isPresent(this.token);
  }

  get token() {
    return localStorage.getItem(TOKEN_KEY);
  }
  set token(value) {
    value !== null
      ? localStorage.setItem(TOKEN_KEY, value)
      : localStorage.removeItem(TOKEN_KEY);
  }

  get isAdminViewingUser() {
    return JSON.parse(localStorage.getItem(FROM_ADMIN_KEY));
  }
  set isAdminViewingUser(value) {
    // Disable all metrics for token authenticated sessions
    this.metrics.enabled = value !== true;

    value !== null
      ? localStorage.setItem(FROM_ADMIN_KEY, JSON.stringify(value))
      : localStorage.removeItem(FROM_ADMIN_KEY);
  }

  get trackRegistered() {
    return JSON.parse(localStorage.getItem(TRACK_REGISTERED_KEY));
  }
  set trackRegistered(value) {
    value !== null
      ? localStorage.setItem(TRACK_REGISTERED_KEY, JSON.stringify(value))
      : localStorage.removeItem(TRACK_REGISTERED_KEY);
  }

  @task({ drop: true })
  *trackLogin() {
    // It's important that the "Registered" event is provided to Intercom which is only booted after login
    if (this.trackRegistered) {
      this.metrics.trackEvent({ event: 'Registered' });
      this.trackRegistered = null;
      return;
    }
    yield rawTimeout(1000);
    this.metrics.trackEvent({ event: 'Logged In' });
  }

  @task({ drop: true })
  *login({ email, password, skipRedirect }) {
    const data = {
      email,
      password,
      beta_signup_token: md5(decodeURIComponent(email)).slice(-10),
    };

    let response;

    try {
      response = yield this.fetch.post('/token', { data });
    } catch (error) {
      return this.handleLoginError(error);
    }

    this.token = response.access_token;
    this.isAdminViewingUser = null;
    if (isTesting || skipRedirect) return;
    this.loadUser.perform();
    this.router.transitionTo('dashboard.overview');
  }

  handleLoginError(error) {
    if (isServerErrorResponse(error) && error.status === 503) {
      this.router.transitionTo('maintenance');
      return;
    }

    if (isUnauthorizedResponse(error)) {
      let explanation = error?.payload?.explanation;
      if (explanation?.match(/Your plan does not support API access/))
        explanation = 'Login not allowed. Please contact support.';
      throw explanation ?? 'Incorrect e-mail or password.';
    }

    throw error;
  }

  logout(redirectToLogin = true) {
    this.metrics.trackEvent({ event: 'Logged Out' });
    this.intercom.shutdown();

    this.invalidate();
    this.user = null;

    if (redirectToLogin) {
      this.router.transitionTo(AFTER_LOGOUT_URL);
    }
  }

  checkConfirmed() {
    if (this.user.id && !this.user.confirmed) {
      this.router.transitionTo('confirm-account');
    }
  }
  @task({ drop: true })
  *loadUser(token) {
    if (this.user) return this.user;

    if (token) {
      this.token = token;
    }

    const users = yield this.store.query('user', { without_stats: true });
    this.user = users.firstObject;
    this.checkConfirmed();
    this.siteData.afterLoadUser(this.user);
    this.theme.afterLoadUser(this.user);
    posthog.identify(
      this.user.id, // distinct_id, required
      { email: this.user.email } // $set, optional
    );

    // If the token exist, we redirect user to the onboarding page
    if (token) {
      this.router.transitionTo('onboarding');
      return this.user;
    } else {
      return this.user;
    }
  }

  ingestQueryParamToken(token) {
    if (token !== this.token) {
      this.intercom.shutdown();
    }
    this.token = token;
    // Persist - in case an admin visits the site with some elses token
    // without the t parameter
    this.isAdminViewingUser = true;
  }

  invalidate() {
    this.token = null;
    this.isAdminViewingUser = null;
  }

  requireActive() {
    if (this.user?.isInactive) this.router.transitionTo('plans');
  }

  requireAuthentication(route) {
    if (this.isAuthenticated) return;

    this.router.transitionTo(route || REQUIRE_AUTHENTICATION_ROUTE);
  }

  prohibitAuthentication(route) {
    if (!this.isAuthenticated) return;

    this.router.transitionTo(route || PROHIBIT_AUTHENTICATION_ROUTE);
  }
}

import Service, { inject as service } from '@ember/service';
import { allSettled, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import isTesting from '../utils/is-testing';
import { htmlSafe } from '@ember/template';

export default class SaveKeywordsService extends Service {
  @service fetch;
  @service discovery;
  @service session;
  @service notifications;
  @service router;

  @tracked keywordTasks;

  get hasKeywordErrors() {
    return this.discovery.keywords.some((keyword) => keyword.errors.length > 0);
  }

  @task({ maxConcurrency: 3, enqueue: true })
  *saveKeywordTask(keyword) {
    try {
      yield keyword.save();
    } catch (err) {
      throw new Error(err);
    }
  }

  @task({ drop: true })
  *updateKeywordTranslationTask(keyword, translation) {
    try {
      yield this.fetch.put(
        `/urls/${keyword.url.id}/keywords/${keyword.id}/update_translation`,
        {
          data: {
            translation: translation,
          },
        }
      );
      this.notifications.success('Translation saved successfully.', {
        autoClear: true,
      });
    } catch (err) {
      this.notifications.error(
        'Failed to save translation. Please try again.',
        {
          autoClear: true,
        }
      );
      throw new Error(err);
    }
  }

  @task({ drop: true })
  *saveKeywordsTask(callback) {
    const clearDuration = isTesting ? 0 : 6000;
    if (this.session.user.keywordLimitReached) {
      this.notifications.error(
        htmlSafe(
          `You have reached your keyword limit! <a href="#">Click here</a> to upgrade your plan.`
        ),
        {
          onClick: () => {
            this.router.transitionTo('/plans');
            this.notifications.clearAll();
          },
        }
      );
      return;
    }

    this.discovery.keywords = [
      ...this.discovery.keywords?.filter((keyword) => keyword.query),
    ];

    if (this.discovery.keywords.length === 0) yield null;
    this.keywordTasks = this.discovery.keywords?.map((keyword) =>
      this.saveKeywordTask.perform(keyword)
    );
    yield allSettled(this.keywordTasks);

    if (!this.hasKeywordErrors) {
      if (typeof callback === 'function') callback();
      if (this.discovery.keywords.length > 0) {
        this.notifications.success(
          `Successfully saved ${this.discovery.keywords.length} keywords.`,
          { autoClear: true, clearDuration }
        );
        return { success: true };
      }
    } else {
      const successfullySavedKeywords = this.discovery.keywords.filter(
        (kw) => kw.id && !kw.isNew
      );
      if (successfullySavedKeywords.length) {
        this.discovery.keywords = this.discovery.keywords.filter(
          (kw) => !kw.isValid || kw.errorStrings.length
        );
        this.notifications.success(
          `Successfully saved ${successfullySavedKeywords.length} keywords.`,
          { autoClear: true, clearDuration }
        );
        this.notifications.warning(
          `But ${this.discovery.keywords.length} keywords were not saved, please check them for errors.`,
          { autoClear: true, clearDuration }
        );
        return { success: false };
      } else {
        this.notifications.error(
          `Failed to save keywords, please check them for errors.`,
          { autoClear: true }
        );
        return { success: false };
      }
    }
    this.keywordTasks = null;
    this.session.user.reload();
    // Reloads the URL so the keyword count in the sidebar updates.
    this.discovery.keywords[0]?.url?.reload();
  }
}

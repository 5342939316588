import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { debounce, later, next } from '@ember/runloop';

export default class NwKeywordTableRowKeywordComponent extends Component {
  @service fetch;
  @service notifications;
  @service saveKeywords;
  @tracked translation;
  @tracked showTranslationInput = false;
  @tracked isTranslationSaving = false;

  constructor() {
    super(...arguments);
    this.translation = this.args.keyword.translation ?? '';
  }

  @action
  updateTranslation() {
    if (this.args.keyword.translation?.trim() === this.translation.trim()) {
      return;
    }

    if (!this.args.keyword.translation && !this.translation) {
      return;
    }

    // Updating the keyword object locally for smooth flow (he real update will be held next)
    this.args.keyword.translation = this.translation;
    this.saveKeywordTask.perform(this.args.keyword, this.translation);
  }

  @action
  onTranslationInputKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.updateTranslation();
    }
  }

  @task({ drop: true })
  *saveKeywordTask(keyword, translation) {
    console.log('save Keyword Task', keyword, translation);
    this.isTranslationSaving = true;
    try {
      yield this.saveKeywords.updateKeywordTranslationTask.perform(
        keyword,
        translation
      );
      this.isTranslationSaving = false;
    } catch (err) {
      this.isTranslationSaving = false;
      throw new Error(err);
    }
  }

  get isTranslationExists() {
    return (
      this.args.keyword.translation &&
      this.args.keyword.translation.trim().length > 0
    );
  }

  @action
  toggleShowTranslationInput() {
    this.showTranslationInput = true;
  }

  @action
  toggleHideTranslationInput() {
    this.showTranslationInput = false;
  }

  @action
  async changeUrlPriority(keyword, url) {
    return this.fetch
      .post(`/urls/${keyword.url.id}/keywords/add_url_priority`, {
        data: { keyword_id: keyword.id, url: url },
      })
      .then(() => {
        // Handle the success scenario
        this.notifications.success('URL priority updated successfully!', {
          autoClear: true,
          clearDuration: 10000,
        });

        window.location.reload();
      })
      .catch((error) => {
        // Handle the error scenario
        this.notifications.error(
          'Error while updating URL priority. Please try again.',
          { autoClear: true, clearDuration: 10000 }
        );
      });
  }
}

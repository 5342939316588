import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import localStorage from 'ember-local-storage-decorator';
import { getOwner } from '@ember/application';
import KeywordsResource from '../resources/keywords';
import KeywordStatsResource from '../resources/keyword-stats';
import KeywordPageFilterState from '../utils/filters/keyword-page-filter-state';
import { format } from 'date-fns';
import posthog from 'posthog-js';

/**
  @argument scope {String} - url, dynamicView, newDynamicView, urlDynamicView, newUrlDynamicView
  @argument url {Url} - optional
  @argument dynamicView {DynamicView}
  @argument page {Integer}
  @argument sort {String}
  @argument direction {String} - asc or desc
  @argument onUpdateParams {Function}
 */
export default class NwKeywordsPage extends Component {
  @service store;
  @service router;
  @service metrics;
  @service dashboardNotifications;
  @service fetch;
  @service session;
  @service('persistence/keyword-list') keywordListPersistence;
  @service userSettings;
  @service dashboardState;

  @tracked selectedDateRange;
  @tracked search;
  @tracked clickPotentialChange;
  @tracked clickPotentialChangeInterval;
  @tracked clickPotentialSelectedInterval;
  @tracked searchVolatilityChange;
  @tracked searchVolatilityChangeInterval;
  @tracked searchVolatilitySelectedInterval;
  @tracked averagePositionChange;
  @tracked averagePositionChangeInterval;
  @tracked averagePositionSelectedInterval;
  @tracked isInlineKeywordTableOpen = false;

  @localStorage('nw:keywords-page:advanced-filters-shown')
  advancedFiltersShown = false;

  defaultLimit = 50;

  keywords = new KeywordsResource({
    owner: getOwner(this),
    afterKeywordsLoaded: this.afterKeywordsLoaded,
  });

  keywordStats = new KeywordStatsResource({ owner: getOwner(this) });

  filterState = new KeywordPageFilterState({
    owner: getOwner(this),
    onChange: this.onFilterChange,
    queryParamFilters: this.args.filters,
  });

  constructor(owner, args) {
    super(owner, args);
    const { type, start, end } = this.keywordListPersistence.parseDateRange();
    this.selectedDateRange = { type, start, end };
  }

  @action
  setupScope() {
    const { dynamicView, url } = this.args;
    this.filterState.setup(dynamicView);
    this.keywords.data = [];
    this.search = null;

    if (url) {
      this.dashboardNotifications.showUrlNotifications(url);
    }

    this.fetchKeywords();
    this.fetchKeywordStats();
  }

  get noKeywords() {
    return this.args.url && !this.args.url?.keyword_count;
  }

  get noData() {
    if (!this.keywordStats.data) {
      return false;
    }

    return (
      Object.values(this.keywordStats?.data)
        ?.filter((e) => typeof e === 'number')
        ?.reduce((a, b) => a + b, 0) === 0
    );
  }

  get groupId() {
    return this.dashboardState?.currentUrlGroup?.id;
  }

  get isUrlPage() {
    return this.args.scope === 'url';
  }

  get isExistingDynamicViewPage() {
    return ['dynamicView', 'urlDynamicView'].includes(this.args.scope);
  }

  get isNewDynamicViewPage() {
    return ['newDynamicView', 'newUrlDynamicView'].includes(this.args.scope);
  }

  get isDynamicViewPage() {
    return this.args.scope.toLowerCase().includes('view');
  }

  @tracked _limit;
  get limit() {
    if (this._limit) return this._limit;

    const searchKeywordUrlId = this.args.url?.id;
    const dynamicViewId = this.args.dynamicView?.id;

    const setting =
      this.userSettings.getSetting({
        searchKeywordUrlId,
        dynamicViewId,
      }) ??
      // Try parent setting (for URL when there is no setting for dynamic view)]
      this.userSettings.getSetting({
        searchKeywordUrlId,
      });

    return setting?.settingsData?.keywords?.limit || this.defaultLimit;
  }
  set limit(limit) {
    const searchKeywordUrlId = this.args.url?.id;
    const dynamicViewId = this.args.dynamicView?.id;
    this.userSettings.saveSetting({
      searchKeywordUrlId,
      dynamicViewId,
      settingsData: { keywords: { limit } },
    });
    this._limit = limit;
  }

  get keywordParams() {
    const { url, dynamicView, page, sort, direction, scope } = this.args;
    const { groupId, limit, search } = this;
    const { encodedFilterGroups } = this.filterState;

    const params = {
      urlId: url?.id ?? undefined,
      dynamicViewId: dynamicView?.id ?? undefined,
      calcPositionChange: false,
      page,
      sort,
      direction,
      limit,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      filterGroups:
        encodedFilterGroups === '[]' ? undefined : encodedFilterGroups,
    };

    if (search) {
      params.search = search;
    }

    if (scope === 'newDynamicView') {
      params.scope = 'account';
      params.groupId = groupId;
    }

    if (scope === 'dynamicView') {
      params.groupId = groupId;
    }

    if (this.clickPotentialChange) {
      params.calculate_click_potential_change = true;
    }

    if (this.clickPotentialChangeInterval) {
      params.calculate_click_potential_change_interval = true;
    }

    if (this.clickPotentialSelectedInterval) {
      params.calculate_click_potential_selected_interval = true;
    }

    if (this.searchVolatilityChange) {
      params.calculate_search_volatility_change = true;
    }

    if (this.searchVolatilityChangeInterval) {
      params.calculate_search_volatility_change_interval = true;
    }

    if (this.searchVolatilitySelectedInterval) {
      params.calculate_search_volatility_selected_interval = true;
    }

    if (this.averagePositionChange) {
      params.calculate_average_position_change = true;
    }

    if (this.averagePositionChangeInterval) {
      params.calculate_average_position_change_interval = true;
    }

    if (this.averagePositionSelectedInterval) {
      params.calculate_average_position_selected_interval = true;
    }

    return params;
  }

  get dateFrom() {
    return format(this.selectedDateRange.start, 'yyyy-MM-dd');
  }

  get dateTo() {
    return format(this.selectedDateRange.end, 'yyyy-MM-dd');
  }

  @action
  fetchKeywords() {
    this.keywords.load.perform(this.keywordParams);
  }

  @action
  reloadDynamicColumns() {
    if (this.isDynamicColumnsEnabled) {
      this.keywords.load.perform(this.keywordParams);
    }
  }

  @action
  fetchKeywordStats() {
    if (this.isNewDynamicViewPage) return;

    const { url, dynamicView } = this.args;
    const { start, end } = this.selectedDateRange;
    const { encodedFilterGroups } = this.filterState;
    this.keywordStats.load({
      url,
      dynamicView,
      startDate: start,
      endDate: end,
      filterGroups:
        encodedFilterGroups === '[]' ? undefined : encodedFilterGroups,
    });
  }

  @action
  onFilterChange() {
    this.resetPage();
    this.fetchKeywords();
    this.fetchKeywordStats();
    this.args.onUpdateParams({ filters: this.keywordParams.filterGroups });
  }

  @action
  afterKeywordsLoaded(params) {
    // Clear notifications after every fetch
    this.dashboardNotifications.clearUrlKeywordsNotifications();
    /*
      Only show keyword notifications on URL pages when
      neither search nor filtering was applied
     */
    const isSearch = Boolean(params.search);
    const isFilter = Boolean(params.filterGroups);
    if (this.isUrlPage && !isSearch && !isFilter) {
      this.showKeywordNotifications();
    }
  }

  showKeywordNotifications() {
    if (!this.args.url || !this.keywords.data) return;

    this.dashboardNotifications.showKeywordNotifications({
      keywords: this.keywords.data,
      user: this.session.user,
      url: this.args.url,
    });
  }

  @action
  toggleAdvancedFilters() {
    this.advancedFiltersShown = !this.advancedFiltersShown;
  }

  resetPage() {
    console.log('oiee5');

    if (String(this.args.page) === '1') return;

    this.args.onUpdateParams({ page: 1 });
  }

  @task({ restartable: true })
  *searchChange(query) {
    yield timeout(150);
    this.resetPage();
    this.search = query;
    this.fetchKeywords();
  }

  @action
  dynamicColumnChange(columns) {
    this.clickPotentialChange = columns?.some(
      (c) => c.name === 'click-potential-change' && !!c.selected
    );
    this.clickPotentialSelectedInterval = columns?.some(
      (c) => c.name === 'click-potential-selected-interval' && !!c.selected
    );
    this.searchVolatilityChange = columns?.some(
      (c) => c.name === 'search-volatility-change' && !!c.selected
    );
    this.searchVolatilitySelectedInterval = columns?.some(
      (c) => c.name === 'search-volatility-selected-interval' && !!c.selected
    );
    this.averagePositionChange = columns?.some(
      (c) => c.name === 'average-position-change' && !!c.selected
    );
    this.averagePositionSelectedInterval = columns?.some(
      (c) => c.name === 'average-position-selected-interval' && !!c.selected
    );

    // Set isDynamicColumnsEnabled to true if any column is selected, otherwise false
    this.isDynamicColumnsEnabled = columns?.some((c) => !!c.selected);

    this.fetchKeywords();
  }

  @action
  setDateRange({ start, end, type }) {
    this.keywordListPersistence.persistDateRange(type);
    this.selectedDateRange = { type, start, end };
    this.fetchKeywordStats();
  }

  @action
  sortKeywords(sort, direction) {
    this.args.onUpdateParams({
      page: 1,
      direction,
      sort,
    });
  }

  @action
  limitUpdated(limit) {
    this.limit = limit;
    this.resetPage();
    this.fetchKeywords();
  }

  @action
  pageUpdated(page) {
    console.log('oiee7');

    this.args.onUpdateParams({ page });
  }

  @action
  trackKeywordAdditionOnExtensivePage() {
    posthog.capture('AddKeywords_ExtensivePage');
  }

  @action
  toggleInlineKeywordTableAndTrack() {
    if (!this.isInlineKeywordTableOpen) {
      posthog.capture('AddKeywords_InlinePopup');
    }
    this.isInlineKeywordTableOpen = !this.isInlineKeywordTableOpen;
  }

  @action
  onInlineKeywordsSave(success = true) {
    this.fetchKeywords();
    this.fetchKeywordStats();
    this.isInlineKeywordTableOpen = !success;
  }
}
